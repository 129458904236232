import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { BACKEND_URL } from '../../../services/QuizApi';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state';
import { handleTakeNextQuestion } from '../../../utils/helpers';

export default function YesNoQuestion() {
    const [choice, setChoice] = useState("");
    const [isFirst, setIsFirst] = useState(true);
    const dispatch = useDispatch();
    const { setQuizProgress } = bindActionCreators(actionCreators, dispatch);
    const takeQuizQuestion = useSelector((state) => state.takeQuizQuestion);
    const completeQuestion = useSelector((state) => state.completeQuestion);
    const quizGeneralSettings = useSelector((state) => state.quizGeneralSettings);
    const isTimeOutForceSubmit = useSelector((state) => state.isTimeOutForceSubmit);
    let submitButtonRef = useRef(null);

    const convertHtmlToText = (text) => {
        const ele = document.createElement('div');
        ele.innerHTML = text;
        return ele.innerText;
    }

    const handleChange = (event) => {
        setChoice(event.target.value);
        if (isFirst) {
            setQuizProgress(
                (completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100
            );
            localStorage.setItem("quizProgress",(completeQuestion / quizGeneralSettings.selectnumberofquestion) * 100);
            setIsFirst(false);
        }
    };

    const handleSubmit = (event) => {
        handleTakeNextQuestion(takeQuizQuestion["question_id"], [choice]);
        setIsFirst(true);
        event.preventDefault();
    }

    useEffect(() => {
        if (isTimeOutForceSubmit) {
            submitButtonRef?.current?.click();
        }
    }, [isTimeOutForceSubmit]);

    return (
        <div className="quizcontainer">
            <div className="quizcontainer">
                <div className="take-common-questions">
                    <div className="question-title">
                        <pre>{convertHtmlToText(takeQuizQuestion["question_title"])}</pre>
                    </div>
                    {takeQuizQuestion?.question_image && (
                        <div className="between-img">
                            <img
                                src={BACKEND_URL + "media/" + takeQuizQuestion?.question_image}
                                alt=""
                            />
                        </div>
                    )}
                    <div className="options-div">
                        {Object.entries(takeQuizQuestion["options"]).map(
                            ([key, value], index) => (
                                <div className="options" key={index}>
                                    <input
                                        className="choice"
                                        type="radio"
                                        name="choice"
                                        id={key}
                                        value={key}
                                        onChange={handleChange}
                                    />
                                    <label htmlFor={key} className="otp-number">
                                        {String.fromCharCode(65 + index)}
                                    </label>
                                    <label htmlFor={key} className="option">
                                        {convertHtmlToText(value)}
                                    </label>
                                </div>
                            )
                        )}
                    </div>
                    {takeQuizQuestion["show_explanation"] && (
                        <div className="question-explanation">
                            <div className="explanation-title">Explanation: </div>
                            <div className="explanation-body">
                                {convertHtmlToText(takeQuizQuestion["explanation_text"])}
                            </div>
                        </div>
                    )}
                    <button
                        className={isFirst ? "btn-submit btn-disable" : "btn-submit"}
                        ref={submitButtonRef}
                        onClick={handleSubmit}
                    >
                        {quizGeneralSettings.selectnumberofquestion === completeQuestion
                            ? "Submit"
                            : "Next Question"}
                    </button>
                </div>
            </div>
        </div>
    )
}
